<template>
  <CRow>
    <CCol col="12" lg="6">
      <AItemViewer :options="{ id: $route.params.id, url: 'admin/property_generation_dates', route: '/admin/property/property_generation_dates', name: 'property_generation_date' }" />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'PropertyGenerationDate'
}
</script>
